<div class="container-fluid">
  <mat-toolbar class="row mat-header no-padding" *ngIf="!isLoginPage">
    <div class="col-sm-4 logo-block">
      <img class="logo" src="{{ logoUrl }}" />
      <a (click)="changeMenuSettings()">
        <button class="navbar-toggler d-md-down-none" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
      </a>
    </div>
    <div class="col-sm-4">

    </div>
    <div class="col-sm-2 pt-1">
      <mat-form-field appearance="outline" *ngIf="lstEnterprises&&lstEnterprises.length>0">
        <!-- <mat-label>Select Enterprise</mat-label> -->
        <mat-select placeholder="Enterprise" name="enterpriseId" [(ngModel)]="groupEnterpriseId" (selectionChange)="setGroupEnterpriseId($event)">
          <mat-option *ngFor="let item of lstEnterprises" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-sm-2 notification-block">
      <span style="font-size: 14px;"> {{ loginName }}</span>
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="loginName">
        <mat-icon><img src="../assets/images/user.png" class="circle login-icon" /></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/user/userprofile']">
          <i class="fa fa-user"></i> <span>&nbsp;&nbsp; User Profile</span>
        </button>
        <button mat-menu-item (click)="signOut()" matTooltio="Signout">
          <i class="fa fa-sign-out"></i> <span>&nbsp;&nbsp; Sign Out</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
  <div id="matDrawer">
    <mat-drawer-container class="example-container app-content" autosize>
      <mat-drawer style="height: 100% !important;" *ngIf="!isProfileUser" #drawer class="example-sidenav" mode="side"
                  opened [ngClass]="isLoginPage ? 'hide-menu' : ''">
        <app-nav-menu [isDefaultMenu]="isDefaultMenu" [menuItems]="menuItems"></app-nav-menu>
      </mat-drawer>
      <div class="example-sidenav-content"></div>

      <div [ngClass]="!isLoginPage ? 'content' : ''">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-container>
  </div>
</div>
<footer class="app-footer" *ngIf="!isLoginPage">
  <div class="ui container">
    <!--ToDo praveen -->
    <!-- &copy; {{ currentYear }} - InfoQuest.All rights reserved. -->
    Powered by Info Quest
  </div>
</footer>
<!--<div  id="dv-loader">
  <div></div>
</div>-->

<div *ngIf="isShowLoader == true" class="lds-facebook">
  <div class="main-loader-sub">
    <div></div>
    <div></div>
    <div></div>
  </div>
 
</div>

<a style="display: none" id="btnDownload"></a>
