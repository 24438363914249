import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { GlobalChecksTypesEnum } from "../constants/ProfileCheckTypes.const";
import { CommonService } from "../services/common.service";
import { CommonDataService } from "../services/commonData.service";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class ExportHelper {
  constructor(public _commonService: CommonService,
    private _commonDataService: CommonDataService) { }

  static processCheckName(caseId: string, checkType: number, checkTypeName: string) {
    const response = { checkTypeName: "", caseId: caseId };
    switch (checkType) {
      case GlobalChecksTypesEnum.EDUCATION_DETAILS:
        response.checkTypeName = "Education";
        response.caseId = "IQ-CASE-${caseId}-EDU".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.ADDRESS_DETAILS:
        response.checkTypeName = "Address";
        response.caseId = "IQ-CASE-${caseId}-ADD".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.CREDIT_CHECK_CIBIL:
        response.checkTypeName = "Cibil";
        response.caseId = "IQ-CASE-${caseId}-CRE".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.CRIMINAL_RECORDVERIFICATIONTHROUGHLAWFIRM:
        response.checkTypeName = "CRINIMAL";
        response.caseId = "IQ-CASE-${caseId}-EC".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.DRUGTEST_9:
        response.checkTypeName = "DRUG";
        response.caseId = "IQ-CASE-${caseId}-DT".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.EMPLOYEE_DETAILS:
        response.checkTypeName = "Employee";
        response.caseId = "IQ-CASE-${caseId}-EMP".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.GLOBAL_DATABASE_CHECK:
        response.checkTypeName = "Global Database";
        response.caseId = "IQ-CASE-${caseId}-DB".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.IDENTITY_PROOFS:
        response.checkTypeName = "Identity";
        response.caseId = "IQ-CASE-${caseId}-ID".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.POLICE_CRIMINAL_CHECK:
        response.checkTypeName = "Police Criminal";
        response.caseId = "IQ-CASE-${caseId}-EC".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.REFERENCE_DETAILS:
        response.checkTypeName = "Reference";
        response.caseId = "IQ-CASE-${caseId}-REF".replace(
          "${caseId}",
          response.caseId
        );
        break;
      case GlobalChecksTypesEnum.CREDIT_CHECK_CIBIL:
        response.checkTypeName = "Cibil";
        response.caseId = "IQ-CASE-${caseId}-CRE".replace(
          "${caseId}",
          response.caseId
        );
        break;
      default:
        response.checkTypeName = checkTypeName;
        response.caseId = "IQ-CASE-${caseId}-Oth".replace(
          "${caseId}",
          response.caseId
        );
        break;
    }
    return response;
  }

  // async downloadReport(profileNumber: string, html: any) {
  //   this._commonService.isShowLoader = true;
  //   const response = await fetch(`${environment.pdfUrl}pdf`, {
  //     method: "POST",

  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ html: html }),
  //   });
  //   this._commonService.isShowLoader = false;
  //   if (response.ok) {
  //     const blob = await response.blob();
  //     const urlObject = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = urlObject;
  //     link.download = `${profileNumber}.pdf`;
  //     document.body.appendChild(link);
  //     link.click();
  //     URL.revokeObjectURL(urlObject);
  //     document.removeChild(link);
  //   } else {
  //     this._commonService.isShowLoader = false;
  //     throw new Error("There was an unexpected error.");
  //   }
  // }

  async downloadReport(profileNumber: string, html: any, enterpriseCode: string, reportTYpe: string = "") {
    this._commonService.isShowLoader = true;
    const model = {
      content: html,
      profileNumber: profileNumber,
      enterpriseCode: enterpriseCode,
      reportType: reportTYpe
    };
    const response = await fetch(`${environment.pdfUrl}api/pdfReport/Report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    });
    this._commonService.isShowLoader = false;
    if (response.ok) {
      const url = `${environment.pdfUrl}FinalRpt/${profileNumber}/${profileNumber}.pdf`;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = `${profileNumber}.pdf`;
      document.body.appendChild(link);
      link.click();
      //   URL.revokeObjectURL(url);
      document.removeChild(link);
      window.open(url, '_blank', 'fullscreen=yes');
    } else {
      this._commonService.isShowLoader = false;
      throw new Error("There was an unexpected error.");
    }
  }

  async publishReport(profileNumber: string, html: any, profileGuid: string) {
    this._commonService.isShowLoader = true;
    const model = {
      content: html,
      profileNumber: profileNumber
    };
    const response = await fetch(`${environment.pdfUrl}api/pdfReport/Report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    });
    this._commonService.isShowLoader = false;
    if (response.ok) {
      const url = `${environment.pdfUrl}FinalRpt/${profileNumber}/${profileNumber}.pdf`;
      const request = {
        profileGuid: profileGuid,
        filePath: url,
      };

      this._commonDataService.postDataAsync("api/Profile/SaveFinalReport", request).pipe(map((res) => {
        if (res) {
          this._commonService.isShowLoader = false;
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.download = `${profileNumber}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.removeChild(link);
        }
        else {
          this._commonService.isShowLoader = false;
          throw new Error("There was an unexpected error.");
        }
      }));
    } else {
      this._commonService.isShowLoader = false;
      throw new Error("There was an unexpected error.");
    }
  }

  static exportToExcel(arrayListtoExp, exportSettings, filename) {
    try {
      var downloadLink;
      var dataType = "data:application/vnd.ms-excel;charset=utf-8;";
      var tableHTML = "<table><thead><tr>";

      exportSettings.forEach((RowItem, RowIndex) => {
        tableHTML += "<th>" + RowItem.text.replace(/([a-z])([A-Z])/g, "$1 $2") + "</th>";
      });

      tableHTML += "</tr></thead><tbody>";

      arrayListtoExp.forEach((RowItem, RowIndex) => {
        tableHTML += "<tr>";
        exportSettings.forEach((propertyName, RowIndex) => {
          tableHTML += "<td>" + RowItem[propertyName['text']] + "</td>";
        })
        tableHTML += "</tr>";
      });
      tableHTML += "</tbody>";
      tableHTML += "</table>";

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      // @ts-ignore
      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        // @ts-ignore
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    } catch (error) { }
  };

  static exportToExcel1(arrayListtoExp, exportSettings, filename) {
    try {
      var downloadLink;
      var dataType = "data:application/vnd.ms-excel;charset=utf-8;";
      var tableHTML = "<table><thead><tr>";

      exportSettings.forEach(function (RowItem, RowIndex) {
        tableHTML += "<th>" + RowItem.text + "</th>";
      });

      tableHTML += "</tr></thead><tbody>";

      arrayListtoExp.forEach(function (RowItem, RowIndex) {
        tableHTML += "<tr>";
        for (var propertyName in RowItem) {
          tableHTML += "<td>" + RowItem[propertyName] + "</td>";
        }
        tableHTML += "</tr>";
      });
      tableHTML += "</tbody>";
      tableHTML += "</table>";

      // Specify file name
      filename = filename ? filename + ".xls" : "excel_data.xls";

      // Create download link element
      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      // @ts-ignore
      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        // @ts-ignore
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
    } catch (error) { }
  };

}
