import {
  AfterViewChecked, ChangeDetectorRef, Component, DoCheck, OnInit,
  ViewEncapsulation
} from "@angular/core";
import {
  ActivatedRoute, NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart, Router
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  appGlobalConstants, userTypesEnum
} from "./common/constants/common.constants";
import { AuthGuard } from "./common/services/auth.service";
import { CommonService } from "./common/services/common.service";
import { SharedService } from "./modules/shared/services/shared.service";
import { MasterModel } from "./common/models/applicant.class";
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewChecked, DoCheck {
  isShowLoader = false;
  isProfileUser = false;
  logoUrl = appGlobalConstants.logoUrl;
  title = "InfoQuest";
  lstEnterprises: MasterModel[];
  groupEnterpriseId: number;
  isLoginPage = false;
  loginName: string;
  isDefaultMenu = true;
  menuItems: any[];
  authguard: any;
  userTypes = userTypesEnum;
  currentYear = new Date().getFullYear();
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _activeRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private _authguard: AuthGuard
  ) {
    this.routeChange();
    this.authguard = _authguard;
  }
  ngOnInit() {
    if (this._commonService.getTokenGuid())
      this.bindMenuItems();
    this.loginName = this._commonService.getLoginName();
    this.logoUrl = this._commonService.getLogoUrl();
    const lstGroupEnterprises = this._commonService.getGroupEnterprises();
    if (lstGroupEnterprises) {
      this.lstEnterprises = <MasterModel[]>lstGroupEnterprises;
    }
    this.groupEnterpriseId = +this._commonService.getGroupEnterpriseId();
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }
  ngDoCheck() {
    if (this._commonService.isShowLoader && this.isShowLoader === false) {
      this.isShowLoader = true;
    } else if (
      this._commonService.isShowLoader === false &&
      this.isShowLoader
    ) {
      this.isShowLoader = false;
    }
  }
  routeChange() {
    this._router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        this._commonService.isShowLoader = true;
      }
      if (event instanceof NavigationEnd) {
        if (event.url === "/login" || event.url === "/reset-password") {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
        this._commonService.isShowLoader = false;
      }
      if (event instanceof NavigationCancel) {
        this._commonService.isShowLoader = false;
      }
      if (event instanceof NavigationError) {
        this._commonService.isShowLoader = false;
      }
    });
  }
  bindMenuItems() {
    this._sharedService.getMenuItems().subscribe(res => {
      this.menuItems = <any>res;
    });
  }
  changeMenuSettings() {
    this.isDefaultMenu = !this.isDefaultMenu;
  }
  signOut() {
    localStorage.clear();
    this._sharedService.signOut().subscribe(res => {
      this.isLoginPage = true;
    });
    this._router.navigate(["/login"]);
  }
  setGroupEnterpriseId(event: any) {
    this._commonService.setGroupEnterpriseId(event.value);
    this._router.navigateByUrl("/dashboard");
  }
}
