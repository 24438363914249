import { Component, Inject, Input, OnInit } from "@angular/core";
import {
    MatDialogRef, MAT_DIALOG_DATA
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import * as _ from "lodash";
import { userTypesEnum } from "src/app/common/constants/common.constants";
import { profileStatusTypesEnum } from "src/app/common/constants/workflow.statustypes";
import { AddExtraChargesRequest, ExtraCharges, ProfileCheckInfoRequest, ProfileCheckResponse, ProfileChecksConfig, ProfileChecksInfoRequest, ProfileConfigResponse } from "src/app/common/models/applicant.class";
import { AuthGuard } from "src/app/common/services/auth.service";
import { CommonService } from "src/app/common/services/common.service";
import { FileService } from "src/app/modules/applicant/services/file.service";
import { ExportHelper } from "../../../../common/helpers/export.helper";
import { ApplicantService } from "../../../applicant/services/applicant.service";
@Component({
    selector: 'app-checks-min-info',
    templateUrl: './checks-min-info.component.html',
    styleUrls: ['./checks-min-info.component.scss']
})
export class ChecksMinInfoComponent implements OnInit {
    @Input() profileCheckRequest: ProfileCheckInfoRequest;
    model: ProfileConfigResponse = new ProfileConfigResponse();
    userTypeId: number;
    authguard: any;
    profileStatusTypes = profileStatusTypesEnum;
    request: ProfileChecksInfoRequest = new ProfileChecksInfoRequest();
    addExtraChargesRequest: AddExtraChargesRequest = new AddExtraChargesRequest();
    extraCharges: ExtraCharges = new ExtraCharges();
    userTypes = userTypesEnum;
    profileCheckResponse: ProfileCheckResponse[];
    constructor(private _applicantService: ApplicantService,
        private _commonService: CommonService,
        private _authguard: AuthGuard,
        private _fileService: FileService,
        private dialogRef: MatDialogRef<ChecksMinInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.authguard = _authguard;
    }
    checkMinInfoColumns: string[] = [
        "caseId",
        // "checkName",
        "assignedTo",
        "checkTypeName",
        "wfStatusTypeName",
        "items",
        "latestRemarks"
        // "checkPrice",
        // "extraCharges"
    ];
    checksListColumns: string[] = [
        "profileCheckType",
        "items",
    ];
    checksMinInfoDataSource = new MatTableDataSource(<ProfileCheckResponse[]>[]);
    checksListDataSource = new MatTableDataSource(<ProfileChecksConfig[]>[]);

    ngOnInit() {
        this.addExtraChargesRequest.extraCharges = [];
        this.userTypeId = +this._commonService.getUserTypeId();
        this.dialogRef.disableClose = true;
        this.request.profileIds = [];
        if (this.profileCheckRequest && this.profileCheckRequest.profileGuid) {
            this.request.profileIds.push(this.profileCheckRequest.profileId);
            this.getProfileConfig(this.profileCheckRequest.enterpriseId, this.profileCheckRequest.profileGuid);
        } else {
            this.request.profileIds.push(this.data.profile.id);
            this.getProfileConfig(this.data.profile.enterpriseId, this.data.profile.profileGuid);
        }
    }

    getProfileInfo(): void {
        this._applicantService
            .getCheckMinInfo<ProfileCheckResponse[]>(this.request)
            .subscribe(res => {
                this.profileCheckResponse = <ProfileCheckResponse[]>res;
                if ((this.model.profileStatusTypeId === profileStatusTypesEnum.PROFILE_IN_PROGRESS_BY_EMPLOYEE || this.model.profileStatusTypeId === profileStatusTypesEnum.PROFILE_REFER_BACK_TO_EMPLOYEE) && this.userTypeId === userTypesEnum.ENTERPRISE_USER) {
                    this.profileCheckResponse = _.uniqBy(this.profileCheckResponse, 'checkTypeId');
                }
                this.profileCheckResponse.forEach(x => {
                    let response = ExportHelper.processCheckName(x.caseId, x.checkTypeId, x.checkTypeName);
                    x.checkTypeName = response.checkTypeName;
                });
                this.profileCheckResponse = _.orderBy(this.profileCheckResponse, ['checkTypeName'], ['asc']);
                this.checksMinInfoDataSource = new MatTableDataSource(this.profileCheckResponse = this.profileCheckResponse.filter(o => o.items > 0));
            });
    }

    getProfileConfig(enterpriseId: number, profileGuid: any) {
        this._applicantService
            .getProfileConfig(enterpriseId, profileGuid)
            .subscribe((res: ProfileConfigResponse) => {
                if (res) {
                    this.model = <ProfileConfigResponse>res;
                    const checkList = _.orderBy(this.model.lstProfileChecksConfigs, ['profileCheckType'], ['asc']);
                    //@ts-ignore
                    this.checksListDataSource = new MatTableDataSource(checkList.filter(o => o.items > 0));
                    this.getProfileInfo();
                }
            });
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    uploadFile(event: any) {
        this._fileService
            .uploadFile(event, { fileType: "EXTRA_CHARGES" })
            .subscribe(res => {
                const response = <any>res;
                this.extraCharges.path = response[0];
            });
    }
    onAddClick(): void {
        this.addExtraChargesRequest.extraCharges.push(this.extraCharges);
        this.extraCharges = new ExtraCharges();
    }
    onDeleteClick(item: ExtraCharges): void {
        this.addExtraChargesRequest.extraCharges = this.addExtraChargesRequest.extraCharges.filter(o => o.cmts != item.cmts);
    }
    onSaveClick(): void {
        this.addExtraChargesRequest.profileId = this.model.id;
        this.addExtraChargesRequest.profileId = this.model.id;
        this._applicantService
            .addExtraCharges<AddExtraChargesRequest[]>(this.addExtraChargesRequest)
            .subscribe(res => {
                const test = <any[]>res;
            });
    }
}
