<div class="nav-menu">
  <div *ngIf="isDefaultMenu">
    <mat-accordion>
      <div *ngFor="let parent of menuItems; let i = index">
        <mat-panel-title *ngIf="parent.lstSubMenuItems.length>0">
          <a mat-menu-item [matMenuTriggerFor]="applicant" routerLinkActive="rlSelected"><i
              class="fa {{ parent.iconPath }}"></i></a>
        </mat-panel-title>
        <mat-menu #applicant="matMenu">
          <a *ngFor="let child of parent.lstSubMenuItems" mat-menu-item [routerLink]="[child.navUrl]">
            <span *ngIf="child.menuText != 'Profile Form'">
              {{ child.menuText }}
            </span>
          </a>
        </mat-menu>
      </div>
    </mat-accordion>
  </div>
  <div *ngIf="!isDefaultMenu">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let parent of menuItems">
        <mat-expansion-panel-header *ngIf="parent.lstSubMenuItems.length > 0">
          <i class="fa {{ parent.iconPath }} mainMenuIcon" aria-hidden="true"></i>
          {{ parent.menuText }}
        </mat-expansion-panel-header>
        <div *ngFor="let child of parent.lstSubMenuItems" routerLinkActive="rlSelected">
          <a routerLinkActive="rlSelected" mat-list-item [routerLink]="[child.navUrl]" class="sub-menu-items">
            <i class="{{ child.IconPath }}" aria-hidden="true"></i>
            <span *ngIf="child.menuText != 'Profile Form'">
              {{ child.menuText }}
            </span>
          </a>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!--
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-dashboard"></i> <span>Dashboard</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-menu-item [routerLink]="['/user/userlist']">Dashboard</a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-users"></i>  <span>Users</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-menu-item [routerLink]="['/user/userlist']">Users</a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-user"></i> <span>Applicants</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-menu-item [routerLink]="['/applicant']">Applicant</a>
        </mat-expansion-panel>
      </mat-accordion>
    -->
  </div>
</div>