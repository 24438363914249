<div>
  <div>
    <div class="text-center ">
      <strong class="page-header-text">Checks Infoformation</strong>
      <a class="pull-right text-right" matTooltip="Close" (click)="onCloseClick()">
        <i class="fa fa-close"></i>
      </a>
    </div>

  </div>
  <hr />

  <div>
    <div>
      <div class="row">
        <div class="col-sm-4">
          <span class="text-bold"> Name : </span>{{ model.firstName }} {{ model.lastName }}
        </div>
        <div class="col-sm-4" *ngIf="authguard.userTypeId != userTypes.ENTERPRISE_USER">
          <span class="text-bold"> Enterprise :</span> {{ model.enterprise }}
        </div>
        <div class="col-sm-2">
          <span class="text-bold">#Profile : </span>{{ model.profileNumber }}
        </div>
        <div class="col-sm-2">
          <span class="text-bold"> Emp Code : </span>{{ model.employeeCode }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <span class="text-bold"> Status : </span>{{ model.profileStatus }}
        </div>
        <div class="col-sm-2">
          <span class="text-bold">Created On : </span>{{ model.createdDate | date: "dd-MMM-yyyy" }}
        </div>

        <div class="col-sm-2"><span *ngIf="model.finalRptColorId">
            <i class="fa fa-file final-rpt-icon {{model.finalRptColorId |
            finalreportColor}}" aria-hidden="true" title="Final Report Color"></i>
          </span>
        </div>
      </div>
    </div>
    <br />
    <div>
      <mat-tab-group>
        <mat-tab label="Checks List">
          <table mat-table [dataSource]="checksListDataSource">
            <ng-container matColumnDef="profileCheckType">
              <th mat-header-cell *matHeaderCellDef>Check Name</th>
              <td mat-cell *matCellDef="let item">{{ item.profileCheckType }}</td>
            </ng-container>
            <ng-container matColumnDef="items">
              <th mat-header-cell *matHeaderCellDef>No of Checks</th>
              <td mat-cell *matCellDef="let item">{{ item.items }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="checksListColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: checksListColumns"></tr>
          </table>
        </mat-tab>
        <mat-tab label="Checks Detail List"  *ngIf="userTypeId!=userTypes.ENTERPRISE_USER">
          <div class="row">
            <div class="col-sm-12">
              <table mat-table [dataSource]="checksMinInfoDataSource">
                <ng-container matColumnDef="caseId">
                  <th mat-header-cell *matHeaderCellDef>
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE">
                      CaseId
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE">
                      <app-check-case-id [isInHrConformation]="item.isInHrConformation" [caseId]="item.caseId" [profileId]="profileId" [checkType]="item.checkTypeId">
                      </app-check-case-id>
                      {{item.caseId}}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="wfStatusTypeName">
                  <th mat-header-cell *matHeaderCellDef>
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE">
                      Status
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE">
                      {{ item.wfStatusTypeName }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="assignedTo">
                  <th mat-header-cell *matHeaderCellDef>
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE&&userTypeId!=userTypes.ENTERPRISE_USER">
                      Assigned To
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div
                      *ngIf="model.profileStatusTypeId!==profileStatusTypes.PROFILE_IN_PROGRESS_BY_EMPLOYEE&&model.profileStatusTypeId!==profileStatusTypes.PROFILE_REFER_BACK_TO_EMPLOYEE&&userTypeId!=userTypes.ENTERPRISE_USER">
                      {{ item.assignedTo }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="checkTypeName">
                  <th mat-header-cell *matHeaderCellDef>Check Type</th>
                  <td mat-cell *matCellDef="let item">{{ item.checkTypeName }}</td>
                </ng-container>
                <ng-container matColumnDef="items">
                  <th mat-header-cell *matHeaderCellDef>
                    <div *ngIf="userTypeId==userTypes.ENTERPRISE_USER">
                      No of checks
                    </div>

                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div *ngIf="userTypeId==userTypes.ENTERPRISE_USER">
                      {{ item.items }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="latestRemarks">
                  <th mat-header-cell *matHeaderCellDef>
                    <div *ngIf="userTypeId!=userTypes.ENTERPRISE_USER">
                      Latest Remarks
                    </div>

                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div *ngIf="userTypeId!=userTypes.ENTERPRISE_USER">
                      {{ item.latestRemarks }}
                    </div>
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="checkPrice">
                  <th mat-header-cell *matHeaderCellDef>
                    <div>
                      Check Price
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div>
                      {{ item.checkPrice }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="extraCharges">
                  <th mat-header-cell *matHeaderCellDef>
                    <div>
                      Extra Charges
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let item">
                    <div>
                      {{ item.checkPrice }}
                    </div>
                  </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="checkMinInfoColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: checkMinInfoColumns"></tr>
              </table>
            </div>

          </div>
          <!-- <div class="row">
            <div class="col-sm-8">
              <div class="min-info-expand-content">
                <mat-expansion-panel style="background-color: #00c89f;pointer-events: none">
                  <mat-expansion-panel-header class="profile-detail-list-header">
                    <mat-panel-title>
                      <div class="row col-sm-12 check-info-header">
                        <div class="col-sm-1">
                          CaseId
                        </div>
                        <div class="col-sm-2">
                          checkTypeName
                        </div>
      
                        <div class="col-sm-2">wfStatusTypeName</div>
                        <div class="col-sm-1">items</div>
                        <div class="col-sm-2">
                          checkPrice
                        </div>
                        <div class="col-sm-2">extraCharges</div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
                <ng-template ngFor let-item [ngForOf]="profileCheckResponse" let-i="index">
                  <mat-accordion>
                    <mat-expansion-panel name="{{ i }}">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="row col-sm-12 ">
                            <div class="col-sm-1">{{ item.caseId }}</div>
                            <div class="col-sm-2">
                              {{ item.checkTypeName }}
                            </div>
                            <div class="col-sm-2">{{ item.wfStatusTypeName }}</div>
                            <div class="col-sm-1">{{ item.items }}</div>
                            <div class="col-sm-2">
                              {{ item.checkPrice }}
                            </div>
                            <div class="col-sm-2">{{ item.extraCharges }}</div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="extra-charges">
                        <div>
                          <div class="row">
                            <div class="col-sm-6"
                              *ngIf="addExtraChargesRequest.extraCharges && addExtraChargesRequest.extraCharges.length>0">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>Amount</th>
                                    <th>Date</th>
                                    <th>Comments</th>
                                    <th>File</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of addExtraChargesRequest.extraCharges">
                                    <td>{{ item.amt }}</td>
                                    <td>{{ item.dt }}</td>
                                    <td>{{ item.cmts }}</td>
                                    <td>
                                      <a><i class="fa fa-download" matTooltip="Download"
                                          (click)="download(item.path)"></i></a>
                                      &nbsp; &nbsp; &nbsp;
                                      <a><i class="fa fa-trash" matTooltip="Delete" (click)="onDeleteClick(item)"></i></a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      dddd
                    </mat-expansion-panel>
                  </mat-accordion>
                </ng-template>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Amount</mat-label><input appCustomValidator [required]="false" type="number" matInput
                      [(ngModel)]="extraCharges.amt" placeholder="Amount" />
                  </mat-form-field>
                </div>
      
                <div class="col-sm-8">
                  <mat-form-field appearance="outline">
                    <mat-label>Comments</mat-label><input appCustomValidator [required]="false" type="text" matInput
                      [(ngModel)]="extraCharges.cmts" placeholder="Comments" custommax="20" />
                  </mat-form-field>
                </div>
      
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <input type="file" (change)="uploadFile($event)" />
                </div>
                <div class="col-sm-1">
                  <a (click)="onAddClick()" title="Save" color="primary">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div class="text-right"> <button type="button" (click)="onSaveClick()" title="Save" mat-raised-button
                  color="primary">
                  Save
                </button>
              </div>
            </div>
          </div> -->

        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>